<mat-toolbar class="footer flex justify-center">
  <span class="mat-caption"
    >Site written in Angular |
    <a
      class="accent"
      href="https://github.com/ChristianGracia/christiangracia.com4.0"
      target="_blank"
      rel="noreferrer"
      style="text-decoration: none"
      >View Code</a
    ></span
  >
</mat-toolbar>
