<mat-toolbar class="flex align-center justify-space-between">
  <div class="flex align-center">
    <button
      class="hide-mobile cg-site-text hover-1"
      mat-button
      [ngClass]="currentUrl === '' ? 'accent-color' : ''"
      aria-label="Home button with menu icon"
      (click)="goToLink('/')"
    >
      christiangracia.com
    </button>
    <div class="flex flex-row justify-center align-center">
      <div>
        <div
          [ngClass]="isDarkTheme ? 'fill-white' : 'fill'"
          [innerHTML]="lightIcon | sanitizedHtml"
        ></div>
      </div>
      <mat-slide-toggle [checked]="isDarkTheme" (change)="toggleTheme($event)">
      </mat-slide-toggle>
      <div>
        <div
          [class.fill]="isDarkTheme"
          [innerHTML]="darkIcon | sanitizedHtml"
        ></div>
      </div>
    </div>
  </div>
  <div class="flex flex-row align-center social-media-container">
    <div
      *ngFor="let social of socialMediaLinks; let i = index"
      class="social-link"
      (click)="openLink(social[0])"
      [innerHTML]="social[1] | sanitizedHtml"
      [ngClass]="
        activeIconArray[i] ? 'fill active' : isDarkTheme ? 'fill-white' : ''
      "
    ></div>
  </div>
  <div class="flex flex-row hide-mobile">
    <button
      aria-label="Nav item"
      class="nav-item hover-1"
      *ngFor="let link of links"
      mat-button
      (click)="goToLink(link)"
      [ngClass]="
        currentUrl === link || (currentUrl === '' && link === 'home')
          ? 'accent-color'
          : ''
      "
    >
      {{ link }}
    </button>
  </div>
  <div class="hide-desktop">
    <button
      mat-button
      color="accent"
      aria-label="Home button with menu icon"
      (click)="onToggleSidenav()"
    >
      <div
        class="flex align-center"
        [innerHTML]="menuIcon | sanitizedHtml"
      ></div>
    </button>
  </div>
</mat-toolbar>
