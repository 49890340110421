<div
  *ngIf="currentSong && !loadingSong"
  class="flex flex-col justify-center song-container"
>
  <div
    class="calc-width song-row flex flex-row align-center flex-wrap justify-space-between"
  >
    <div class="flex flex-row align-center song-left-col">
      <img
        loading="lazy"
        height="36px"
        width="36px"
        [src]="imagePrefix + currentSong.images[0]"
        alt="{{ currentSong?.artist }} album image"
      />
      <div class="flex flex-col justify-center song-info-container">
        <span class="accent-color mat-caption text-bold"
          >{{
            currentSong.name.length > 25
              ? currentSong.name.substring(0, 20) + "..."
              : currentSong.name
          }}
        </span>
        <span class="mat-caption text-bold">{{ currentSong.artist }}</span>
      </div>
    </div>

    <div class="flex flex-row align-center clickable">
      <div
        *ngIf="songIndex > 0"
        (click)="skipSong(-1)"
        [innerHTML]="rewindIcon | sanitizedHtml"
      ></div>
      <div
        (click)="playPreviewOfSong()"
        *ngIf="currentSong.preview"
        [innerHTML]="(!songPlaying ? playIcon : pauseIcon) | sanitizedHtml"
      ></div>
      <div
        (click)="skipSong(1)"
        *ngIf="!loadingRecentlyPlayed"
        [innerHTML]="fastForwardIcon | sanitizedHtml"
      ></div>
      <mat-spinner
        *ngIf="loadingRecentlyPlayed"
        diameter="22"
        color="accent"
      ></mat-spinner>
    </div>
  </div>
  <div class="flex flex-col calc-width">
    <mat-progress-bar
      aria-valuemin="00:00"
      aria-valuemax="00:30"
      color="accent"
      mode="determinate"
      value="{{ progress }}"
    ></mat-progress-bar>
    <div
      class="flex flex-row justify-space-between song-progress-bar-text-container align-center mt-5"
    >
      <div>
        {{ currentSong.progressString }}
      </div>
      <div class="flex flex-row align-center">
        <div class="text-bold">Track: {{ songIndex + 1 }}/</div>
        <div *ngIf="!loadingRecentlyPlayed" class="text-bold">
          {{ 50 + (currentlyPlayingSong ? 1 : 0) }}
        </div>
        <mat-spinner
          *ngIf="loadingRecentlyPlayed"
          class="mat-caption"
          color="accent"
          diameter="12"
        ></mat-spinner>
      </div>
      <div>
        {{ currentSong.durationString }}
      </div>
    </div>
  </div>
  <div class="current-text flex flex-row align-center mat-display-1">
    <app-lazy-animated-icon></app-lazy-animated-icon>
    <div class="mat-caption" *ngIf="currentSong.playedAt">
      Last played at {{ currentSong.playedAt }}
    </div>
    <ng-template
      class="loading loading-animation"
      ngFor
      let-arr
      [ngForOf]="currentText"
      *ngIf="!currentSong.playedAt"
    >
      <div class="loading loading-animation" style="padding: 2px">
        <span *ngFor="let char of arr">
          {{ char }}
        </span>
      </div>
    </ng-template>
  </div>
</div>
