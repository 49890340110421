import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  constructor(private http: HttpClient) {}

  public createObservable(
    method: string,
    url: string,
    options = {},
    useApiUrl = true
  ) {
    return this.http[method](
      `${useApiUrl ? environment.apiUrl : ""}${url}`,
      options
    );
  }
}
