<mat-nav-list class="side-nav-bar">
  <div class="site-navigation-header">
    <span
      class="site-navigation-text mat-subheading-2"
      (click)="goToLink('home')"
      >christiangracia.com</span
    >
  </div>
  <div class="line background-accent-color"></div>
  <div class="link-row flex justify-center" *ngFor="let link of links">
    <a
      mat-list-item
      (click)="goToLink(link)"
      class="nav-caption mat-subheading-2"
      ><div
        [ngClass]="
          currentUrl === link || (currentUrl === '' && link === 'home')
            ? 'accent-color'
            : ''
        "
      >
        {{ link }}
      </div>
    </a>
  </div>
</mat-nav-list>
