<div class="home-container">
  <span class="mat-display-4 name">Christian Gracia</span>
  <div class="calc-width graph animated-line"></div>
  <span class="mat-display-2 description">
    I'm a software engineer who likes to build full stack web and mobile apps
    with React, Node.js, Angular, Java, Python, C#, React Native, Swift, and
    more.
  </span>
  <div class="song-container">
    <app-current-song></app-current-song>
  </div>
</div>
<div class="flex justify-center learn-more-container">
  <button
    (click)="goToAboutPage()"
    class="hover-1"
    mat-raised-button
    color="accent"
  >
    Learn More
  </button>
</div>
